import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditProperty from "./components/pagesComponents/manageProperty/editProperty/EditProperty";
import AuthPublicRoute from "./components/privateRoutes/AuthPrivateRoute";
import PrivateRoute from "./components/privateRoutes/PrivateRoute";
import PublicRoute from "./components/privateRoutes/PublicRoute";
import UserDashBoardCommon from "./components/privateRoutes/UserDashBoard";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ChangePassword from "./pages/adminDashboard/changePassword/changePassword";
import ViewMore from "./pages/adminDashboard/dashboard/viewMore/viewMore";
import Details from "./pages/adminDashboard/organizations/details/details";
import Organizations from "./pages/adminDashboard/organizations/organizations/organizations";
import ViewProperty from "./pages/adminDashboard/organizations/property";
import TipTransactionDetail from "./pages/adminDashboard/organizations/property/tipTransactionDetail";
import ViewAssignedProperties from "./pages/adminDashboard/organizations/property/view-assigned-properties";
import Partners from "./pages/adminDashboard/partners/partners";
import Service from "./pages/adminDashboard/service/service";
import Tippers from "./pages/adminDashboard/tippers/tippers/tippers";
import ViewTips from "./pages/adminDashboard/tippers/viewTips/viewTips";
import ForgotPassword from "./pages/auth/forgotPassword";
import Login from "./pages/auth/login";
import ResetPassword from "./pages/auth/resetPassword";
import RedirectAsAdminPage from "./pages/redirectAsAdminPage";
import ManageProperties from "./pages/userDashboard/manageProperties/ManageProperties";
import ManageViewProperty from "./pages/userDashboard/manageProperties/property";
import ManagePropertyViewTip from "./pages/userDashboard/manageProperties/property/tipTransactionDetail";
import ManageAssignedProperties from "./pages/userDashboard/manageProperties/property/view-assigned-properties";
import ManagerAccounts from "./pages/userDashboard/managerAccounts";
import PaymentManagement from "./pages/userDashboard/paymentManagement";
import Profile from "./pages/userDashboard/profile";
import QRCodeAndLinks from "./pages/userDashboard/qrCodeAndLink";
import "./scss/App.scss";
import Settings from "./pages/adminDashboard/dashboardSettings";
import Reporting from "./pages/adminDashboard/reporting/reporting";
import ReportAutomation from "./pages/userDashboard/reportAutomation";
import StripeSetUpIndication from "./components/stripeSetupIndication/SetUpSuccess";
import SetUpSuccess from "./components/stripeSetupIndication/SetUpSuccess";
import SetUpError from "./components/stripeSetupIndication/SetUpError";
// import ErrorTestComponent from "./components/errorTest/ErrorTestComponent";

const App = () => {
  return (
    <div className="App">
      <Routes>
        {/* Auth Routes */}
        <Route
          index
          element={
            <AuthPublicRoute>
              <Login />
            </AuthPublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthPublicRoute>
              <ForgotPassword />
            </AuthPublicRoute>
          }
        />
        <Route
          path="/redirect-as-admin-page/:token"
          element={
            <PublicRoute>
              <RedirectAsAdminPage />
            </PublicRoute>
          }
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/set-password/:token" element={<ResetPassword />} />
        {/* Dashboard Routes */}
        <Route
          path="/:uuid?/dashboard"
          element={
            <PrivateRoute>
              <UserDashBoardCommon />
            </PrivateRoute>
          }
        />
        <Route path="/dashboard/all-tips" element={<ViewMore />} />
        {/* Service-categories Route we don't need anymore*/}
        {/* <Route path="/service-categories" element={<ServiceCategories />} /> */}
        {/* Service Routes */}
        <Route path="/service" element={<Service />} />
        {/* Organizations Routes */}
        <Route path="/organizations" element={<Organizations />} />
        <Route path="/organizations/view/:uuid" element={<Details />} />
        <Route
          path="/organizations/view/:id/property/view/:id"
          element={<ViewProperty />}
        />
        <Route
          path="/organizations/view-tip-transactions"
          element={<ManagePropertyViewTip />}
        />
        <Route
          path="organizations/view-assigned-properties"
          element={<ViewAssignedProperties />}
        />
        <Route
          path="organizations/tip-transaction-detail"
          element={<TipTransactionDetail />}
        />
        {/* Partner Routes */}
        <Route path="/partners" element={<Partners />} />
        <Route path="/:uuid?/reporting" element={<Reporting />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/:uuid?/change-password" element={<ChangePassword />} />
        {/* Tipplers Routes */}
        <Route path="/tippers" element={<Tippers />} />
        <Route path="/tippers/view-tips/:tip_uuid" element={<ViewTips />} />
        {/* reporting Routes */}
        {/* <Route path="/reporting" element={<Reporting />} /> */}
        {/* User-Dashboard Routes -> Manage Properties */}
        <Route
          path="/:uuid?/manage-properties"
          element={<ManageProperties />}
        />
        <Route
          path="/:uuid?/manage-properties/edit/:id"
          element={<EditProperty />}
        />
        <Route
          path="/:uuid?/manage-properties/view-property/:id"
          element={<ManageViewProperty />}
        />{" "}
        <Route
          path="/:uuid?/organizations/view-property/:id"
          element={<ManageViewProperty />}
        />
        <Route
          path="/organizations/view-assigned-properties/:id"
          element={<ManageAssignedProperties />}
        />
        <Route
          path="/:uuid?/view-assigned-properties/:id"
          element={<ManageAssignedProperties />}
        />
        <Route
          path="/:uuid?/manager-accounts/view-assigned-properties/:id"
          element={<ManageAssignedProperties />}
        />
        <Route
          path="/:uuid?/manage-properties/view-property-tips/view-tip-transactions"
          element={<ManagePropertyViewTip />}
        />
        <Route path="/:uuid?/manager-accounts" element={<ManagerAccounts />} />
        <Route path="/:uuid?/QR-Codes-&-links" element={<QRCodeAndLinks />} />
        <Route path="/:uuid?/profile" element={<Profile />} />
        <Route
          path="/:uuid?/reporting-automation"
          element={<ReportAutomation />}
        />
        <Route
          path="/:uuid?/payment-management"
          element={<PaymentManagement />}
        />
        {/* <Route path="/success" element={<Success />} /> */}
        {/* <Route path="/reject" element={<Reject />} /> */}
        <Route path="*" element={<PageNotFound />} />
        <Route path="/setup" element={<SetUpSuccess />} />
        <Route path="/setup-error" element={<SetUpError />} />
        {/* <Route path="/bugsnag-error" element={<ErrorTestComponent />} /> */}
      </Routes>
      <ToastContainer autoClose={false} newestOnTop />
      {/* <ErrorTestComponent /> */}
    </div>
  );
};

export default App;
