/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FormikProps } from "formik";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import Select, { GroupBase, OptionsOrGroups, Props } from "react-select";

interface IProps extends Props {
  name: string;
  label?: string;
  options: OptionsOrGroups<
    { label: any; value: any },
    GroupBase<{ label: any; value: any }>
  >;
  formik?: FormikProps<any>;
  formikErrorMesg?: any;
}

const ReactSelectField: React.FC<IProps> = ({
  name,
  formik,
  options,
  label,
  formikErrorMesg,
  value,
  ...rest
}) => {
  const formikValue = formik?.values[name] ? formik?.values[name] : value;
  const formikError = formik?.touched[name] ? formik?.errors[name] : null;
  useEffect(() => {
    if (name === "country_id" && !formikValue?.value) {
      formik?.setFieldValue("country_id", { value: 1, label: "United States" });
    }
  }, [formikValue]);

  return (
    <Form.Group
      className={`react_select_wrap ${rest.className}`}
      controlId={name}
    >
      {label ? <Form.Label>{label}</Form.Label> : null}
      <Select
        components={{
          IndicatorSeparator: () => null,
        }}
        isSearchable
        options={options}
        classNamePrefix="react_select"
        onChange={(e: any) => {
          if (name === "state") {
            formik?.setFieldValue("state", e.id);
          } else {
            formik?.setFieldValue(name, e ?? "");
          }
        }}
        // onChange={
        //   formik ? (e: any) => formik?.setFieldValue(name, e ?? "") : () => {}
        // }
        {...rest}
        styles={{
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled ? "" : "none",
              wordBreak: "break-word",
              wordWrap: "break-word",
              color: isSelected
                ? "#2549E8 !important"
                : isDisabled
                ? "#a9b0bf !important"
                : "",
              ":active": {
                backgroundColor: "none",
              },
              cursor: isDisabled ? "not-allowed" : "pointer",
            };
          },
        }}
        escapeClearsValue={true}
        value={
          name === "state"
            ? options?.filter((item: any) => item?.id === formikValue)
            : formikValue
        }
        className={`${formikError ? "has_error" : ""}`}
      />
      {formikError && formikErrorMesg ? (
        <span className="error">
          {typeof formikErrorMesg?.value === "string"
            ? formikErrorMesg?.value
            : ""}
        </span>
      ) : (
        <span className="error">
          {typeof formikError === "string" ? formikError : ""}
        </span>
      )}
    </Form.Group>
  );
};

export default ReactSelectField;
