import {
  PropertyIcon,
  charts,
  lock,
  mangerAccount,
  organization,
  patner,
  qrIcon,
  reportIcon,
  reporting,
  services,
  setting,
  tipper,
} from "../../assests/Icons/icon";

//* import roles from Global variables
import { ORGANIZATION, SUPERADMIN } from "../../common/globalVariables";

//* Define your Nav-Link according to role
const NavItems = [
  {
    link: "/dashboard",
    title: "Dashboard",
    icon: charts,
    role: SUPERADMIN,
  },
  // {
  //   link: "/service-categories",
  //   title: "Service Categories",
  //   icon: checkout,
  //   role: SUPERADMIN,
  // },
  {
    link: "/service",
    title: "Services",
    icon: services,
    role: SUPERADMIN,
  },
  {
    link: "/organizations",
    title: "Organizations",
    icon: organization,
    role: SUPERADMIN,
  },
  {
    link: "/partners",
    title: "Partners",
    icon: patner,
    role: SUPERADMIN,
  },
  {
    link: "/tippers",
    title: "Tippers",
    icon: tipper,
    role: SUPERADMIN,
  },
  {
    link: "/reporting",
    title: "Reporting",
    icon: reporting,
    role: SUPERADMIN,
  },
  {
    link: "/change-password",
    title: " Change Password",
    icon: lock,
    role: SUPERADMIN,
  },
  {
    link: "/settings",
    title: "Settings",
    icon: setting,
    role: SUPERADMIN,
  },
  {
    // link: "#user-dashboard",
    link: "/dashboard",
    title: "Dashboard",
    icon: charts,
    role: ORGANIZATION,
  },
  {
    link: "/manage-properties",
    title: "Manage Properties",
    icon: PropertyIcon,
    role: ORGANIZATION,
  },
  {
    link: "/manager-accounts",
    title: "Manager Accounts",
    icon: mangerAccount,
    role: ORGANIZATION,
  },
  {
    link: "/QR-Codes-&-links",
    title: "QR Codes & Links",
    icon: qrIcon,
    role: ORGANIZATION,
  },
  {
    link: "/reporting",
    title: "Reporting",
    icon: reportIcon,
    role: ORGANIZATION,
  },
];

export default NavItems;
