import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

import Bugsnag from "@bugsnag/js";

export const notifyBugsnagError = (
  error: Error | string,
  metadata?: Record<string, any>
) => {
  Bugsnag.notify(error, (event) => {
    if (metadata) {
      event.addMetadata("custom", metadata);
    }
  });
};

export const isValidUUID = (uuid: string | undefined) => {
  return typeof uuid === "string" && uuid.trim() !== "" && uuid !== "undefined";
};

/** get data on the basis of condition */
export const getCurrentUserData = (uuid: string) => {
  const isAdminAsOrg =
    window.location.href.includes("mydashboard") && isValidUUID(uuid);
  if (isAdminAsOrg) {
    return getOrgLocalUserData(uuid);
  } else {
    return getLocalUserData();
  }
};

/** Handle local storage data for organization  */
export const setOrgLocalUserData = (uuid: string, data: string) => {
  localStorage.setItem(uuid + "_org_data", Encrypt(parseJwt(data)));
  localStorage.setItem(uuid, Encrypt(data));
};

/** Handle local storage data */
export const setLocalUserData = (data: string) => {
  localStorage.setItem("tip_userData", Encrypt(parseJwt(data)));
  localStorage.setItem("tip_token", Encrypt(data));
};

export const getLocalUserData = () =>
  localStorage.getItem("tip_userData")
    ? Decrypt(localStorage.getItem("tip_userData"))
    : "";

/** get  local storage data for organization  */
export const getOrgLocalUserData = (uuid: string) => {
  return localStorage.getItem(uuid + "_org_data")
    ? Decrypt(localStorage.getItem(uuid + "_org_data"))
    : "";
};

export const getOrgLocalUserToken = (uuid: string) =>
  localStorage.getItem(uuid) ? Decrypt(localStorage.getItem(uuid) || "") : "";

export const getUserToken = (uuid: string) => {
  const isAdminAsOrg =
    window.location.href.includes("mydashboard") && isValidUUID(uuid);
  if (isAdminAsOrg) {
    return getOrgLocalUserToken(uuid);
  } else {
    return localStorage.getItem("tip_token")
      ? Decrypt(localStorage.getItem("tip_token") || "")
      : "";
  }
};

export const clearUserLocalData = (uuid: string) => {
  const isAdminAsOrg =
    window.location.href.includes("mydashboard") && isValidUUID(uuid as string);
  if (isAdminAsOrg) {
    localStorage.removeItem(uuid);
    localStorage.removeItem(uuid + "_org_data");
  } else {
    localStorage.removeItem("tip_userData");
    localStorage.removeItem("tip_token");
  }
};

/** Encrypt Decrypt Data */
const cryptoSecret = process.env.CRYPTO_KEY ?? "";
export function Encrypt(values: any) {
  const encJson = CryptoJS.AES.encrypt(
    JSON.stringify(values),
    cryptoSecret
  ).toString();
  const encData = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(encJson)
  );
  return encData;
}
export function Decrypt(values: string | null) {
  let bytes = null;
  try {
    const decData = CryptoJS.enc.Base64.parse(
      values == null ? "" : values
    ).toString(CryptoJS.enc.Utf8);
    bytes = CryptoJS.AES.decrypt(decData, cryptoSecret).toString(
      CryptoJS.enc.Utf8
    );
  } catch (e) {}
  return bytes ? JSON.parse(bytes) : {};
}

/** Parse JWT Data */
export const parseJwt = (token: string) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

export const removeInitialSpace = (value: string) => value.replace(/^\s+/g, "");

export function isValidUrl(url: string) {
  if (url && url?.length <= 50) {
    return true;
  } else {
    return false;
  }
}

export function isValidEmail(email: string) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(email);
}

export const copyToClipBoard = (text: string) => {
  if (typeof text === "string") {
    navigator.clipboard
      .writeText(text + "/c")
      .then(() => {
        showToast("Copied to clipboard!", "success");
      })
      .catch((err) => {
        showToast("Failed to copy", "error");
      });
  }
};

export const openQrCode = (QrLink: string) => {
  window.open(QrLink, "_blank");
};

export const showToast = (message: string, type: "success" | "error") => {
  if (type === "success") {
    toast.dismiss();
    toast.success(message, { autoClose: 5000 });
  } else if (type === "error") {
    toast.dismiss();
    toast.error(message);
  }
};

const s2ab = (s: string) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
};

export const convertToExcel = (tableData: any[]) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(tableData);
  // Calculate column widths based on the content length
  const wscols = tableData[0].map((_: any, colIndex: string | number) => ({
    wch: Math.max(
      ...tableData.map((row: { [x: string]: any }) =>
        row[colIndex] ? String(row[colIndex]).length : 0
      )
    ),
  }));
  // Set column widths in the worksheet
  ws["!cols"] = wscols;
  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  // Generate a binary string from the workbook
  const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
  // Convert the binary string to a Blob
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });
  // Create a download link
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "jinwen_investors.xlsx");
  // Trigger the download
  document.body.appendChild(link);
  link.click();
  // Clean up
  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 0);
};

export const getFrequency = (freq: number | string) => {
  let frequency = "";
  switch (freq) {
    case 1:
      frequency = "Daily";
      break;
    case 2:
      frequency = "Weekly";
      break;
    case 3:
      frequency = "Bi-Weekly";
      break;
    case 4:
      frequency = "Monthly";
      break;
    default:
      break;
  }
  return frequency;
};
