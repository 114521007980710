import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  eye,
  reportingcard1,
  reportingcard2,
  reportingcard3,
  reportingcard4,
  reportingcard5,
  reportingcard6,
  stripeIcon,
  tipIcon,
} from "../../../assests/Icons/icon";
import searchIcon from "../../../assests/svg/searchIcon.svg";
import {
  getCurrentUserData,
  notifyBugsnagError,
  showToast,
} from "../../../common/utils/Functions";
import DashboardLayout from "../../../components/layouts/DashboardLayout";
import PageHeading from "../../../components/pageHeading";
import RangeDateModal from "../../../components/rangeDateModal/rangeDateModal";
import ReactExcel from "../../../components/reactExcel/ReactExcel";
import ReactTable from "../../../components/reactTable/ReactTable";
import ReportingCards from "../../../components/reportingCards";
import InputField from "../../../components/theme/InputField";
import ReactSelectField from "../../../components/theme/ReactSelectField";
import ThemeButton from "../../../components/theme/ThemeButton";
import TipAmountModal from "../../../components/tipAmountModal/tipAmountModal";
import useFetch from "../../../hooks/useFetch";
import { cardDataType } from "../../../types/common";
import { OrganizationItem } from "../../../types/organization";
import styles from "./reporting.module.scss";

const Reporting = () => {
  const location = useLocation();

  const { uuid } = useParams();
  const userData = getCurrentUserData(`${uuid}`);
  const [show, setShow] = useState(false);
  const [reportingData, setReportingData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<1 | 2 | 3 | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [showRange, setShowRange] = useState(false);
  const [excelData, setExcelData] = useState<any>([]);
  const [filterValues, setFilterValues] = useState<any>([]);
  const [employeeFilterValues, setEmployeeFilterValues] = useState<any>([]);
  const [employeeValues, setEmployeeValues] = useState<any>(null);
  const [serviceFilterValue, setServiceFilterValue] = useState<any>([]);
  const [serviceValue, setServiceValue] = useState<any>(null);
  const [organizationUuid, setOrganizationUuid] = useState(null);
  const [propertyUuid, setPropertyUuid] = useState(null);
  const [employeeUuid, setEmployeeUuid] = useState(null);
  const [serviceUuid, setServiceUuid] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState<{
    value: string;
    label: string;
  } | null>(null);

  /**
   * @Pagination states
   * 1. pageLimit    :- store limit of data that will render on current page
   * 2. totalRecords :- store total number of data in service api
   * 3. currentPage  :- store current page number
   */
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const handleCloseRange = () => setShowRange(false);
  const handleShowRange = () => setShowRange(true);
  const [tipItems, setTipItems] = useState<any>([]);
  const [dashboardTipItems, setDashboardTipItems] = useState<any>([]);
  const [showTip, setShowTip] = useState(false);
  const [showTip2, setShowTip2] = useState(false);
  const handleTipClose = () => setShowTip(false);
  const handleTipClose2 = () => setShowTip2(false);

  const [
    getReportingData,
    {
      response: reportingDataResponse,
      error: reportingDataError,
      loading: reportingDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  /** Api definition */
  const [
    getExcelData,
    {
      response: excelDataResponse,
      error: excelDataError,
      loading: excelDataLoading,
    },
  ] = useFetch("/dashboard/all-tips", {
    method: "POST",
  });

  /** Api definition */
  const [orgDataApi, { response: orgDataResponse, error: orgDataError }] =
    useFetch("/organization/list", {
      method: "post",
    });

  /** Api definition */
  const [propDataApi, { response: propDataResponse, error: propDataError }] =
    useFetch("/property/list", {
      method: "post",
    });

  /** Api definition */
  const [
    employeeDataApi,
    { response: employeeDataResponse, error: employeeDataError },
  ] = useFetch("/propertyemployees/list", {
    method: "post",
  });

  const [
    serviceDataApi,
    { response: serviceDataResponse, error: serviceDataError },
  ] = useFetch("/organization/all-property-services", {
    method: "post",
  });

  useEffect(() => {
    if (reportingDataResponse) {
      setTotalRecords(reportingDataResponse.data?.totalRecords);
      let updatedList;
      if (userData?.role_id === 2 || userData?.role_id === 3) {
        updatedList = reportingDataResponse.data?.list.map((item: any) => ({
          org_uuid: item?.organization_details?.user_details?.uuid,
          organizationName: item?.organization_details?.name,
          propertyName: item?.property_details?.name,
          prop_uuid: item?.property_details?.uuid,
          tipperEmail: item?.tipper_details?.email,
          service: item?.property_service_details?.service_details?.name,
          // name: item?.property_details?.name,
          tipper_uuid: item?.tipper_details?.uuid,
          employee: item?.property_employee_details?.name,
          room: item?.property_room_details?.name,
          netTipAmount: item?.net_tip_amount?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          }),
          date: moment(item?.createdAt).format("MM/DD/YYYY"),
        }));
      } else {
        updatedList = reportingDataResponse.data?.list?.map((item: any) => ({
          org_uuid: item?.organization_details?.user_details?.uuid,
          organizationName: item?.organization_details?.name,
          propertyName: item?.property_details?.name,
          prop_uuid: item?.property_details?.uuid,
          tipperEmail: item?.tipper_details?.email,
          service: item?.property_service_details?.service_details?.name,
          // name: item?.property_details?.name,
          tipper_uuid: item?.tipper_details?.uuid,
          employee: item?.property_employee_details?.name,
          room: item?.property_room_details?.name,
          netTipAmount: item?.net_tip_amount ? (
            <span
              style={{
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {item?.net_tip_amount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
              {userData?.role_id === 1 && (
                <>
                  <span
                    onClick={() => {
                      setDashboardTipItems([
                        {
                          label: "Total Trans. Amount",
                          value: item?.total_amount
                            ? item?.total_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                            : "--",
                        },
                        {
                          label: "Stripe Fee",
                          value: item?.stripe_fee
                            ? item?.stripe_fee.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                            : "--",
                        },
                        {
                          label: "TopTipper Fee",
                          value: item?.top_tipper_fees
                            ? item?.top_tipper_fees.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                            : "--",
                        },
                        {
                          label: "Net Tip Amount",
                          value: item?.net_tip_amount
                            ? item?.net_tip_amount.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })
                            : "--",
                        },
                        {
                          label: '',
                          value: <div className={styles.stripeId}>
                            {stripeIcon}{" "}<span>{" "}{item?.stripe_connect_account_id ?? '--'}</span>
                          </div>
                        }

                      ]);
                      setShowTip2(true);

                    }}
                  >
                    {" "}
                    {tipIcon}
                  </span>

                </>
              )}
            </span>
          ) : (
            "--"
          ),
          date: moment(item?.createdAt).format("MM/DD/YYYY"),
        }));
      }
      setReportingData(updatedList);
    }
    if (reportingDataError) {
      showToast(reportingDataError.message as string, "error");
    }
  }, [reportingDataResponse, reportingDataError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      try {
        getReportingData({
          start: (currentPage - 1) * pageLimit,
          limit: pageLimit,
          startDate,
          endDate,
          organization_uuid: organizationUuid,
          property_uuid: propertyUuid,
          employee_uuid: employeeUuid,
          service_uuid: serviceUuid,
          sortedBy: sortBy,
          search: searchValue,
        });
      } catch (e: any) {
        notifyBugsnagError(e, { api: "getReportingData" });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [
    searchValue,
    currentPage,
    pageLimit,
    sortBy,
    startDate,
    endDate,
    organizationUuid,
    propertyUuid,
    employeeUuid,
    serviceUuid,
  ]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (userData?.role_id === 1) {
        try {
          orgDataApi({
            start: 0,
            limit: -1,
            search: "",
            stripe_status: null,
            is_active: 1,
          });
        } catch (e: any) {
          notifyBugsnagError(e, {
            api: "orgDataApi",
          });
        }
      }
      if (userData?.role_id === 2 || userData?.role_id === 3) {
        try {
          propDataApi({
            start: 0,
            limit: -1,
            search: "",
            is_active: 1,
          });
          employeeDataApi({
            start: 0,
            limit: -1,
            search: "",
            property_uuid: propertyUuid,
            is_active: 1,
          });
          serviceDataApi({
            property_uuid: propertyUuid,
          });
        } catch (e: any) {
          notifyBugsnagError(e, {
            api: "propDataApi || employeeDataApi || serviceDataApi",
          });
        }
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [propertyUuid]);

  /** Handle response */
  useEffect(() => {
    if (excelDataResponse) {
      let updateOrgExcel;
      if (userData?.role_id === 2 || userData?.role_id === 3) {
        updateOrgExcel = excelDataResponse.data.list?.map((item: any) => {
          return {
            org_name: item?.org_name ?? "--",
            prop_name: item?.property_name ?? "--",
            email: item?.tipper_details?.email ?? "--",
            amount:
              item?.net_tip_amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "--",
            service:
              item?.property_service_details?.service_details?.name ?? "--",
            // name: item?.property_details?.name,

            employee: item?.property_employee_details?.name ?? "--",
            room: item?.property_room_details?.name ?? "--",
            time_of_tip: item?.createdAt
              ? moment
                .utc(item?.createdAt)
                .subtract(4, "hours")
                ?.format("HH:mm A")
              : "--",
            date: moment(item?.createdAt).format("MM/DD/YYYY") ?? "--",
            stripe_account_number: item?.stripe_connect_account_id ?? "--"
          };
        });
      } else {
        updateOrgExcel = excelDataResponse.data.list?.map((item: any) => {
          return {
            org_name: item?.org_name ?? "--",
            prop_name: item?.property_name ?? "--",
            partner_name:
              item?.organization_details?.partner_details?.name ?? "--",
            email: item?.tipper_details?.email ?? "--",
            amount:
              item?.net_tip_amount.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) ?? "--",
            stripe_fee: item?.stripe_fee ? "$" + item?.stripe_fee : "--",
            tip_fees: item?.top_tipper_fees
              ? "$" + item?.top_tipper_fees
              : "--",
            gross_amount: item?.total_amount ? "$" + item?.total_amount : "--",
            service:
              item?.property_service_details?.service_details?.name ?? "--",
            // name: item?.property_details?.name,

            employee: item?.property_employee_details?.name ?? "--",
            room: item?.property_room_details?.name ?? "--",
            time_of_tip: item?.createdAt
              ? moment
                .utc(item?.createdAt)
                .subtract(4, "hours")
                ?.format("HH:mm A")
              : "--",
            date: moment(item?.createdAt).format("MM/DD/YYYY") ?? "--",
            stripe_account_number: item?.stripe_connect_account_id ?? "--"
          };
        });
      }
      setExcelData([...updateOrgExcel]);
    }
    if (excelDataError) {
      showToast(excelDataError.message as string, "error");
    }
  }, [excelDataResponse, excelDataError]);

  const handleExcelApi = () => {
    try {
      getExcelData({
        start: 0,
        limit: -1,
        startDate,
        endDate,
        organization_uuid: organizationUuid,
        property_uuid: propertyUuid,
        employee_uuid: employeeUuid,
        service_uuid: serviceUuid,
        sortedBy: sortBy,
        search: searchValue,
      });
    } catch (e: any) {
      notifyBugsnagError(excelDataError, {
        api: "getExcelData",
      });
    }
  };

  useEffect(() => {
    let updateOrg;
    if (orgDataResponse) {
      updateOrg = orgDataResponse?.data?.list?.map((item: OrganizationItem) => {
        return {
          value: item?.uuid,
          label: item?.name,
        };
      });
    }
    if (propDataResponse) {
      updateOrg = propDataResponse?.data?.list?.map(
        (item: OrganizationItem) => {
          return {
            value: item?.uuid,
            label: item?.name,
          };
        }
      );
    }
    if (employeeDataResponse) {
      const empData = employeeDataResponse?.data?.list?.map(
        (item: OrganizationItem) => {
          return {
            value: item?.uuid,
            label: item?.name,
          };
        }
      );
      setEmployeeFilterValues([{ label: "All", value: null }, ...empData]);
    }
    if (serviceDataResponse) {
      const serviceData = serviceDataResponse?.data?.map(
        (item: OrganizationItem) => {
          return {
            value: item?.service_details?.uuid,
            label: item?.service_details?.name,
          };
        }
      );
      setServiceFilterValue([{ label: "All", value: null }, ...serviceData]);
    }

    if (updateOrg) {
      setFilterValues([{ label: "All", value: null }, ...updateOrg]);
    }
  }, [
    orgDataResponse,
    orgDataError,
    propDataResponse,
    propDataError,
    serviceDataResponse,
    employeeDataResponse,
  ]);

  const handleDateFilter = (value: string) => {
    const today = moment().format("MM/DD/YYYY");
    let startDate = null;
    let endDate = null;
    switch (value) {
      case "week":
        startDate = moment().startOf("week").format("MM/DD/YYYY");
        break;
      case "month":
        startDate = moment().startOf("month").format("MM/DD/YYYY");
        break;
      case "today":
        startDate = today;
        break;
      case "all":
        break;
      default:
        break;
    }
    if (value !== "all" && startDate) {
      endDate = today;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const filters = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
    { value: "Custom", label: "Custom" },
  ];

  const options = [
    { value: 1, label: "Date" },
    { value: 2, label: "Highest Tip Amount" },
    { value: 3, label: "Lowest Tip Amount" },
  ];
  const CardsData: cardDataType[] =
    userData?.role_id === 1
      ? [
        {
          icon: reportingcard1,
          title: "Total Number of Tips",
          value: reportingDataResponse?.data?.totalRecords
            ? reportingDataResponse?.data?.totalRecords
            : "--",
          amount: "",
        },
        {
          icon: reportingcard2,
          title: "Total Net Tip Amount",
          value: "",
          amount: reportingDataResponse?.data?.net_tip_amount
            ? reportingDataResponse?.data?.net_tip_amount?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
            : "--",
        },
        {
          icon: reportingcard3,
          title: "Total TopTipper Revenue",
          value: "",
          amount: reportingDataResponse?.data?.top_tipper_revenue
            ? reportingDataResponse?.data?.top_tipper_revenue?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
            : "--",
        },
        {
          icon: reportingcard4,
          title: (
            <span>
              Highest Tip Amount{" "}
              <span
                className={styles.check}
                onClick={() => {
                  setTipItems([
                    {
                      label: "Organization",
                      value: reportingDataResponse?.data?.highest_tip_org_name
                        ? reportingDataResponse?.data?.highest_tip_org_name
                        : "--",
                    },
                    {
                      label: "Tipper Email",
                      value: reportingDataResponse?.data
                        ?.highest_tip_tipper_email
                        ? reportingDataResponse?.data
                          ?.highest_tip_tipper_email
                        : "--",
                    },
                    {
                      label: "Total Trans. Amount",
                      value: reportingDataResponse?.data
                        ?.highest_tip_total_transction_amount
                        ? reportingDataResponse?.data?.highest_tip_total_transction_amount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )
                        : "--",
                    },
                    {
                      label: "Stripe Fee",
                      value: reportingDataResponse?.data
                        ?.highest_tip_stripe_fee
                        ? reportingDataResponse?.data?.highest_tip_stripe_fee.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )
                        : "--",
                    },
                    {
                      label: "TopTipper Fee",
                      value: reportingDataResponse?.data
                        ?.highest_tip_top_tipper_fee
                        ? reportingDataResponse?.data?.highest_tip_top_tipper_fee.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )
                        : "--",
                    },
                    {
                      label: "Net Tip Amount",
                      value: reportingDataResponse?.data
                        ?.highest_tip_net_tip_amount
                        ? reportingDataResponse?.data?.highest_tip_net_tip_amount.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )
                        : "--",
                    },
                  ]);
                  setShowTip(true);
                }}
              >
                {" "}
                {tipIcon}
              </span>
            </span>
          ),
          value: "",
          amount: reportingDataResponse?.data?.highest_tip_amount
            ? reportingDataResponse?.data?.highest_tip_amount?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
            : "--",
        },
        {
          icon: reportingcard5,
          title: "Highest Number of Tips by Tipper",
          value: reportingDataResponse?.data?.highest_number_of_tips_by_tipper
            ? reportingDataResponse?.data?.highest_number_of_tips_by_tipper
            : "--",
          amount: "",
        },
        {
          icon: reportingcard6,
          title: "Average Tip Amount",
          value: "",
          amount: reportingDataResponse?.data?.average_tip_amount
            ? reportingDataResponse?.data?.average_tip_amount?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
            : "--",
        },
      ]
      : [
        {
          icon: reportingcard1,
          title: "Total Number of Tips",
          value: reportingDataResponse?.data?.totalRecords
            ? reportingDataResponse?.data?.totalRecords
            : "--",
          amount: "",
        },
        {
          icon: reportingcard2,
          title: "Total Net Tip Amount",
          value: "",
          amount: reportingDataResponse?.data?.net_tip_amount
            ? reportingDataResponse?.data?.net_tip_amount?.toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "USD",
              }
            )
            : "--",
        },
      ];

  const headings =
    userData?.role_id === 1
      ? [
        {
          label: "Organization Name",
          key: "organizationName",
        },
        { label: "Property Name", key: "propertyName" },
        { label: "Tipper Email", key: "tipperEmail" },
        { label: "Net Tip Amount", key: "netTipAmount" },
        { label: "Date", key: "date" },
      ]
      : [
        { label: "Property Name", key: "propertyName" },
        { label: "Tipper Email", key: "tipperEmail" },
        { label: "Service", key: "service" },
        { label: "Employee", key: "employee" },
        { label: "Room", key: "room" },
        { label: "Net Tip Amount", key: "netTipAmount" },
        { label: "Tip Date", key: "date" },
      ];
  const excelHeaders =
    userData?.role_id === 1
      ? [
        { key: "org_name", header: "Organization Name	", width: 40 },
        { key: "prop_name", header: "Property Name", width: 40 },
        { key: "partner_name", header: "Partner Name", width: 40 },
        { key: "email", header: "Tipper Email", width: 40 },
        { key: "amount", header: "Net Tip Amount", width: 40 },
        { key: "stripe_fee", header: "Stripe Fee", width: 40 },
        { key: "tip_fees", header: "TopTipper Fee", width: 40 },
        {
          key: "gross_amount",
          header: "Gross Transaction Amount",
          width: 40,
        },
        { key: "time_of_tip", header: "Time Of Tip", width: 40 },
        { key: "date", header: "Date", width: 40 },
        { key: "stripe_account_number", header: "Stripe Account Number", width: 40 },
      ]
      : [
        { key: "prop_name", header: "Property Name", width: 40 },
        { key: "email", header: "Tipper Email", width: 40 },
        { key: "service", header: "Service", width: 40 },
        { key: "employee", header: "Employee", width: 40 },
        { key: "room", header: "Room", width: 40 },
        { key: "amount", header: "Net Tip Amount", width: 40 },
        { key: "time_of_tip", header: "Time Of Tip", width: 40 },

        { key: "date", header: "Tip Date", width: 40 },
        { key: "stripe_account_number", header: "Stripe Account Number", width: 40 },
      ];
  const actionButtonOptions = [
    {
      name: "View Organization",
      icon: eye,
      onClick: (item: any) => {
        navigate(
          uuid
            ? "/" + uuid + `/organizations/view/${item?.org_uuid}`
            : `/organizations/view/${item?.org_uuid}`
        );
      },
    },
    {
      name: "View Property",
      icon: eye,
      onClick: (item: any) => {
        navigate(
          uuid
            ? "/" + uuid + `/organizations/view-property/${item?.prop_uuid}`
            : `/organizations/view-property/${item?.prop_uuid}`
        );
      },
    },
    {
      name: "View Tippers",
      icon: eye,
      onClick: (item: any) => {
        navigate(
          uuid
            ? "/" + uuid + `/tippers/view-tips/${item?.tipper_uuid}`
            : `/tippers/view-tips/${item?.tipper_uuid}`
        );
      },
    },
  ];
  useEffect(() => {
    if (location.state?.hasOwnProperty("filterValue")) {
      setSelectedFilter(location.state.filterValue);
      handleDateFilter(location.state.filterValue.value);
    }
  }, [location]);

  return (
    <DashboardLayout>
      <div className={styles.dash_page}>
        <Container>
          <Row className="g-xl-4 g-3">
            <Col xxl={12} xl={12}>
              <PageHeading
                heading="Reporting"
                subHeading={
                  userData?.role_id === 1
                    ? "Here is the information about all your reports."
                    : "Here is the information about all tips."
                }
              >
                <div className={styles.search}>
                  <div className={styles.searchField}>
                    <InputField
                      name="search_organization_name"
                      icon={searchIcon}
                      placeholder="Search by Property Name"
                      value={searchValue}
                      onChange={(e) => {
                        setCurrentPage(1);
                        setSearchValue(e.target.value);
                      }}
                      autoComplete="off"
                    />
                    {searchValue ? (
                      <span
                        className={styles.crossIcon}
                        onClick={() => setSearchValue("")}
                      >
                        <RxCross1 />
                      </span>
                    ) : null}
                  </div>
                </div>
              </PageHeading>
            </Col>
            <div className={styles.tipsWrap}>
              <ReactSelectField
                name="sortBy"
                options={options}
                label="Sort By"
                onChange={(e: any) => {
                  setCurrentPage(1);
                  setSortBy(e.value);
                }}
              />
              <ReactSelectField
                name="Sort By"
                options={filters}
                label="Filter By"
                value={selectedFilter}
                onChange={(e: any) => {
                  if (e.value === "Custom") {
                    handleShowRange();
                  }
                  handleDateFilter(e.value);
                  setSelectedFilter(e);
                }}
              />
              <ReactSelectField
                name={userData?.role_id === 1 ? "organizations" : "properties"}
                options={filterValues}
                label={userData?.role_id === 1 ? "Organizations" : "Properties"}
                onChange={(e: any) => {
                  if (userData?.role_id === 1) {
                    setOrganizationUuid(e.value);
                  }
                  if (userData?.role_id === 2 || userData?.role_id === 3) {
                    setPropertyUuid(e.value);
                    setEmployeeValues(null);
                    setEmployeeUuid(null);
                    setServiceUuid(null);
                    setServiceValue(null);
                  }
                }}
              />
              {userData?.role_id === 2 && (
                <ReactSelectField
                  value={serviceValue}
                  name={"services"}
                  options={serviceFilterValue}
                  label={"Services"}
                  onChange={(e: any) => {
                    setServiceUuid(e.value);
                    setServiceValue(e);
                  }}
                />
              )}
              {userData?.role_id === 2 && (
                <ReactSelectField
                  name={"employee"}
                  options={employeeFilterValues}
                  label={"Employees "}
                  onChange={(e: any) => {
                    setEmployeeValues(e);
                    setEmployeeUuid(e.value);
                  }}
                  value={employeeValues}
                />
              )}
              <ReactExcel
                setExcelData={setExcelData}
                loading={excelDataLoading}
                onClick={handleExcelApi}
                buttonTitle="Export CSV"
                fileType="xlsx"
                fileName="Tips List"
                coulmns={excelHeaders}
                excelData={excelData}
              />
            </div>
            <ReportingCards cardData={CardsData} />

            <ReactTable
              data={reportingData || []}
              headings={headings}
              actionButtonOptions={
                userData?.role_id === 1 ? actionButtonOptions : []
              }
              currentPage={currentPage}
              itemPerPage={pageLimit}
              totalItems={totalRecords}
              setPerPageLimit={setPageLimit}
              pageLimit={pageLimit}
              setCurrentPage={setCurrentPage}
              pagination={true}
              statusColumn={false}
              loading={reportingDataLoading || !reportingData}
              actionColumn={userData?.role_id === 1}
            />
          </Row>
          <TipAmountModal
            show={showTip}
            handleClose={handleTipClose}
            title="Highest Tip Amount"
            tipItems={tipItems}
          >
            <ThemeButton onClick={() => handleTipClose()}>Close</ThemeButton>
          </TipAmountModal>

          <TipAmountModal
            show={showTip2}
            handleClose={handleTipClose2}
            title="Tip Amount Information"
            tipItems={dashboardTipItems}
          >
            <ThemeButton onClick={() => handleTipClose2()}>Close</ThemeButton>
          </TipAmountModal>
          {selectedFilter?.value === "Custom" && (
            <RangeDateModal
              show={showRange}
              handleClose={handleCloseRange}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          )}
        </Container>
      </div>
    </DashboardLayout>
  );
};
export default Reporting;
